export const Double1bed=[
{
	image:'./imgs/double1bed-1.jpg',
	key:3,
},
{
	image:'./imgs/double1bed-2.jpg',
	key:4,
},
{
	image:'./imgs/d1-r4-bath.jpg',
	key:5,
	},
{
	image:'./imgs/d1bath.jpg',
	key:51,
	},	
	]