export const RoomCard=[
	{
		key:1,
		roomName:`經典四人房`,
		price:`平日：2280，假日2580\n連續假日，春節期間不適用`,
		image0:'./imgs/classicroom4-1.jpg',
		image1:'./imgs/classicroom4-2.jpg'
	},
	{
		key:2,
		roomName:"溫馨四人房",
		price:`平日：2580，假日2880\n連續假日，春節期間不適用`,
		image0:'./imgs/room4-1.jpg',
		image1:'./imgs/room4-2.jpg'
	},
	{
		key:3,
		roomName:"三人房",
		image0:'./imgs/room3-1.jpg',
		price:`平日：2080，假日2280\n連續假日，春節期間不適用`,
		image1:'./imgs/room3-2.jpg'
	},
	{
		key:4,
		roomName:"雙人房(一大床)",
		price:`平日：1580，假日1780\n連續假日，春節期間不適用`,
		image0:'./imgs/double1bed-1.jpg',
		image1:'./imgs/double1bed-2.jpg',
	},
	{
		key:5,
		roomName:"雙人房(兩小床)",
		price:`平日：1580，假日1780\n連續假日，春節期間不適用`,
		image0:'./imgs/double2bed-1.jpg',
		image1:'./imgs/double2bed-2.jpg'
	},

]