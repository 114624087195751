export const Classicroom4=[
	{
	image:'./imgs/classicroom4-1.jpg',
	key:0,
	},
	{
	image:'./imgs/classicroom4-2.jpg',
	key:1,
	},
	{
	image:'./imgs/c4-r3-bath.jpg',
	key:2,
	},
	{
	image:'./imgs/c4bath.jpg',
	key:21,
	},

]