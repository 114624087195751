export const Double2bed=[
	{
	image:'./imgs/double2bed-1.jpg',
	key:6,
	},
	{
	image:'./imgs/double2bed-2.jpg',
	key:7,
	},
	{
	image:'./imgs/d2bath.jpg',
	key:8,
	},
	{
	image:'./imgs/d2-bath.jpg',
	key:81,
	},

]