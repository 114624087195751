export const Room4=[
	{
	image:'./imgs/room4-1.jpg',
	key:12,
	},
	{
	image:'./imgs/room4-2.jpg',
	key:13,
	},
	{
	image:'./imgs/d1-r4-bath.jpg',
	key:14,
	},
	{
	image:'./imgs/r4bath.jpg',
	key:141,
	},

]