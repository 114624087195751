export const Room3=[
	{
	image:'./imgs/room3-1.jpg',
	key:9,
	},
	{
	image:'./imgs/room3-2.jpg',
	key:10,
	},
	{
	image:'./imgs/c4-r3-bath.jpg',
	key:11,
	},
	{
	image:'./imgs/r3bath.jpg',
	key:111,
	},

]