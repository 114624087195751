// import img1 from "../../../imgs/1.jpg";
// import img2 from "../../../imgs/2.jpg";
// import img3 from "../../../imgs/3.jpg";
// import img4 from "../../../imgs/4.jpg";
// import img5 from "../../../imgs/5.jpg";

export const Sliderimgs=[
	{
		key:1,
		image:'./imgs/hotel-L.jpg'
	},

	{	
		key:2,
		image:'./imgs/lobby1.jpg'
	},

	{
		key:3,
		image:'./imgs/lobby2.jpg'
	},

	


	
]