import "../style/notice.css";
const Notice=()=>{
	return(
		<div className="notice">
			<h1 className="notice_title">住宿須知</h1>
			<div className="notice_grid">
				<h3>匯款資訊</h3>
				<p>
					匯款銀行 : 高雄銀行前金分行 &nbsp;&nbsp;代號 : 016
					<br />
					匯款帳號 : 202102011181 &nbsp;&nbsp;戶名 : 亞歌旅館
					<br />
					會計聯絡 : 蔡小姐 &nbsp;&nbsp;TEL : (07)286-2933 &nbsp;&nbsp;FAX : (07)285-4113
					<br />
					地址 : 高雄市前金區明星街155號
				</p>
			</div>
			<div className="notice_grid"><h3>訂房方式</h3>
				<p>
				1.請您於早上10：00〜晚上10：00打電話或是用LINE等方式訂房，
				詢問您所要的訂房日期，若收到訂房信息後，請於2日內匯訂金（房價的30％），
				連續假日、春節需匯全額，
				逾期恕不保留客房，不便處請見諒。
				</p>
			</div>
			<div className="notice_grid"><h3>匯款須知</h3>
				<p>
					1.匯款前請詳閱訂房須知的各項規定，匯款後視同認同我們的訂房須知各項規定。
				</p>
				<p>	
					2.匯款後，請麻煩以信息/來電/LINE等方式，告您匯款帳號的後五碼
					(無摺存款請在備註欄位填入訂房的名字)，以便查詢款項是否成功入帳，謝謝。 
				</p>
			</div>
			<div className="notice_grid"><h3>住宿規定</h3>
				<p>
					1.入住時間下午 3 : 00以後 退房為隔天早上 11 : 00 之前 (請在入住前30分鐘先行知,謝謝!!) 
					<br />
					2. 請確實遵守入住/退房時間。
					<br />
					3. 請勿攜帶寵物住宿，若自行攜帶寵物者，將拒絕房客住宿；若已付訂金 將不退還訂金。
					<br />
					4. 為了大家的身體健康及住宿品質，本商旅全面禁止吸煙。
					<br />
					5. 為了提供舒適與美觀的生活空間，房間設施若有擺設物品及易碎物品若帶小朋友，請自我約束注意安全。
					<br />
					6. 如未依規定，造成房內物件污損或破壞，須負清潔費或依原價賠償。
					<br />
					7.入住時請記得出示您的證件，以便我們登記，在辦理登記的同時，也請您將住宿費一併繳交。

				</p>
			</div>
			<div className="notice_grid"><h3>取消訂房</h3>
				<p>
					 * 本商旅訂金之退還，依照觀光局頒佈《定型化契約》規定，並依法令規定比率進行取消訂房之扣款如下：
				</p>
				<ul>
				  <li >旅客住宿日當日取消訂房，扣預付訂金金額100%</li>
				  <li >旅客於住宿日前1日內取消訂房，扣房價預付訂金金額80%</li>
				  <li >旅客於住宿日前2-3日內取消訂房，扣房價預付訂金金額70%</li>
				  <li >旅客於住宿日前4-6日內取消訂房，扣房價預付訂金金額60%</li>
				  <li >旅客於住宿日前7-9日內取消訂房，扣房價預付訂金金額50%</li>
				  <li >旅客於住宿日前10-13日內取消訂房，扣房價預付訂金金額30%</li>
				  <li >旅客於住宿日前14日前(含14日)取消訂房，扣房價預付訂金金額0%</li>
				</ul>
				<p>
					* 如遇天災(如地震、颱風)導致交通中斷無法前來，經相關主管單位發佈之訊息，可退還訂金 100% 。 
				</p>
				<p>
					* 若為個人因素 ( 如：確診或是同行確診、當天下雨天、買不到車票、旅伴臨時不同行、心情不佳….. )，請彼此體諒，我們還是依據規定比例退款。
				</p>
			</div>
		</div>
		);
}
export default Notice;