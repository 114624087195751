import Nav from "../../Components/nav.js";
import Googlemap  from "./components/googlemap.js";
import Footer from "../../Components/footer.js";
const Mapp=()=>{
	return(
		<div>
			<Nav/>
			<Googlemap/>
			<Footer/>
		</div>
			
		);
};
export default Mapp;